// CardInfo.js
import React from 'react';
import './CardInfo.css';
import mirIcon from '../../../assets/icons/mir.png';
import visaIcon from '../../../assets/icons/visa.png';
import mastercardIcon from '../../../assets/icons/mastercard.png';

// Helper function to select the appropriate icon based on card type
const cardTypeIcon = (type) => {
    switch (type.toUpperCase()) {
        case 'MIR':
            return mirIcon;
        case 'VISA':
            return visaIcon;
        case 'MASTERCARD':
            return mastercardIcon;
        default:
            return null;
    }
};

const CardInfo = ({ cardData }) => {

    if (cardData) {
        const icon = cardTypeIcon(cardData.cardType);

        return (
            <div className="card-preview">
                <div className="card-type">
                    {icon ? (
                        <img src={icon} alt={cardData.cardType} className="card-type-icon" />
                    ) : (
                        <span>{cardData.cardType}</span>
                    )}
                </div>
                <div className="card-number">**** **** **** {cardData.cardLastFour}</div>
                <div className="card-details">
                    <div className="card-holder">
                        <label>Issuer</label>
                        <span>{cardData.issuer}</span>
                    </div>
                    <div className="card-expiry">
                        <label>Expires</label>
                        <span>{cardData.cardExpDate}</span>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default CardInfo;
