import React, { useState, useEffect } from 'react';
import './ActiveSubscriptions.css';
import appleLogo from '../../../assets/icons/apple-logo.png';
import googleLogo from '../../../assets/icons/google-logo.png';
import promocodeLogo from '../../../assets/icons/promocode-logo.png';
import cardLogo from '../../../assets/icons/card-logo.png';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../context/ModalContext';
import Loader from '../../Common/Loader/Loader';
import CardInfo from './CardInfo';
import authApi from '../../../utils/authApi';

const ActiveSubscriptions = ({ activePurchases, premiumPromocode, fetchUserData }) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState(false);

  const handleSubscriptionCancel = async (subscriptionId) => {
     try {
      setLoading(true);
      let response = await authApi.post('/api/v1/purchase/cloudpayments/cancelSubscription', { SubscriptionId: subscriptionId });
      showModal(t('Success'), <p>{t('Manage subscription cancel result success')}</p>, {
        closeButtonType: 'button',
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        showModal(t('Error'), <p>{t("Manage subscription cancel result fail", err.response.data.message)}</p>, {
          closeButtonType: 'button',
        });
      } else {
        showModal(t('Error'), <p>{t("Manage subscription cancel result fail", "Unknown")}</p>, { closeButtonType: 'button' });
      }
    } finally {
      fetchUserData();
      setLoading(false);
    }
  };

  const handleSubscriptionCancelDialog = async (subscriptionId) => {
    const body = (
      <div>
        <p>{t('Manage subscription cancel confirmation text')}</p>

        <button className="button3 cancel-subscription-button" onClick={() => { hideModal(); handleSubscriptionCancel(subscriptionId); }}>
          {t('Manage subscription cancel')}
        </button>
      </div>
    );

    showModal(t("Manage subscription cancel confirmation title"), body, { closeButtonType: 'icon' });
  };

  const handleOpenSubscriptionInfo = async (data) => {
    const cardData = data.product.cardData;
    const body = (
      <div className="subscription-modal-content">  
        <div style={{marginTop: "5px", marginBottom: "10px"}}>
          <CardInfo cardData={cardData} />
        </div>
        <div className="detail-section">
          <div className="detail-label">{t('Manage subscription next payment')}</div>
          <div className="detail-value">{data.endDate}</div>
        </div>
        
        <div className="detail-section">
          <div className="detail-label">{t('Manage subscription subscription status')}</div>
          <div className="detail-value-status">{t('Manage subscription subscription status Active')}</div>
        </div>
  
        <div className="detail-section">
          <div className="detail-label">{t('Manage subscription next payment price')}</div>
          <div className="detail-value">{data.price}</div>
        </div>
  


        <button className="button3 cancel-subscription-button" onClick={() => { hideModal(); handleSubscriptionCancelDialog(data.id) }}>
          {t('Manage subscription cancel')}
        </button>
      </div>
    );
  
    showModal(t("Manage subscription title"), body, { closeButtonType: 'icon' });
  };



  const purchases = activePurchases.map((purchase) => {
    const { product, validation } = purchase;

    let provider = '';
    let icon = null;
    let type = t('Active subscription forever');
    let title = '';

    switch (product.method) {
      case 'GooglePlay':
        provider = 'Google';
        icon = googleLogo;
        break;
      case 'AppStore':
        provider = 'Apple';
        icon = appleLogo;
        break;
      case 'CloudPayments':
        provider = 'CloudPayments';
        icon = cardLogo;
        break;
      default:
        provider = product.method;
        icon = null;
        break;
    }

    if (product.type === 'subscription') {
      if (product.productId.includes('mounthly') || product.productId.includes('month')) {
        type =  t('Active subscription per month');
      } else if (product.productId.includes('year')) {
        type = t('Active subscription per year');
      } else {
        type = t('Active subscription subscription');
      }
    }

    if (product.grantAccess === 'premium') {
      title = t('Active subscription subscription title', { type: type })
    } else if (product.grantAccess === 'delete-ads') {
      title = t('Active subscription no ads');
    } else {
      title = t('Active subscription subscription');
    }

    let price = product.type === 'subscription' && validation.data 
      ? `${validation.data.price} ${validation.data.currency}` 
      : t('Active subscription one-time purchase');

    let subId = product.subscriptionId ? product.subscriptionId : product.purchaseId;

    return {
      id: subId,
      provider,
      title,
      endDate: product.type === 'subscription' && validation.expireTime 
        ? new Date(validation.expireTime).toLocaleDateString('ru-RU') 
        : null,
      price,
      icon,
      product: product
    };
  });

  if (premiumPromocode) {
    const expirationDate = new Date(Date.now() + premiumPromocode);
    purchases.push({
      id: 'promocode',
      provider: 'Promocode',
      title: t('Active subscription subscription title', { type: t('Active subscription promocode') }),
      endDate: expirationDate.toLocaleDateString('ru-RU'),
      price: null,
      icon: promocodeLogo,
    });
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="profile-section">
      <h2 className="profile-section-title">{t('Active subscription title')}</h2>
      <div className="active-subscriptions-container items-list">
        {purchases.length > 0 ? (
          purchases.map((subscription) => (
            <div key={subscription.id} className="subscription-item profile-section-white-box">
                {subscription.icon && (
                  <img src={subscription.icon} alt={`${subscription.provider} Logo`} className="subscription-icon" />
                )}
                <div className="subscription-info">
                  <div className="text-title">
                    {subscription.title}
                  </div>
                  {subscription.endDate && (
                    <div className="text-description">
                      {t('Active subscription active untill', { date: subscription.endDate })}
                    </div>
                  )}
                  {/* <div className="text-description">
                    {subscription.price}
                  </div> */}

              </div>
              {subscription.provider === 'CloudPayments' && (<button className="button1 manage-sub-bt" onClick={() => handleOpenSubscriptionInfo(subscription)}>{t('Active subscription manage subscription')}</button>)}
            </div>
          ))
        ) : (
          <div className="subscription-item profile-section-white-box"><span className="text-regular">{t('Active subscription no subscriptions')}</span></div>
        )}
      </div>
    </div>
  );
};

export default ActiveSubscriptions;
