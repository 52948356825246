// src/components/TestLoginButton/TestLoginButton.js

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import './TestLoginButton.css'; // Подключаем CSS

const TestLoginButton = () => {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2000';
  const [fingerprint, setFingerprint] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load();

    // Get the visitor identifier when you need it.
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;
        setFingerprint(visitorId);
      });
  }, []);

  const handleTestLogin = async () => {
    console.log("GGWP");
    try {
      const response = await axios.post(`${API_URL}/api/v1/auth/test-login`, {
        username: 'testUser',
        fingerprint,
      });

      const { access, refresh } = response.data;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);

      console.log('Test login successful');
      navigate('/profile'); // Перенаправляем на страницу профиля после успешного входа
    } catch (error) {
      console.error('Error logging in test user', error);
      alert(t('Error logging in test user'));
    }
  };

  return (
    <button onClick={handleTestLogin} className="auth-button facebook-button button1">
      {t('Test Login')}
    </button>
  );
};

export default TestLoginButton;
