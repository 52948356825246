import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css'; // Импорт Navbar specific styles
import '../../App.css'; // Импорт global styles including button2 class
import Loader from '../Common/Loader/Loader'; // Импорт компонента Loader
import authApi from '../../utils/authApi'; // Импорт authApi
import { getFingerprint } from '../../utils/fingerprint'; // Импорт функции получения fingerprint

const Navbar = () => {
  const [isLoading, setIsLoading] = useState(false); // Состояние для отображения лоадера
  const [fingerprint, setFingerprint] = useState('');
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const { t } = useTranslation();

  useEffect(() => {
    // Получаем fingerprint при загрузке Navbar
    const fetchFingerprint = async () => {
      const visitorId = await getFingerprint();
      setFingerprint(visitorId);
    };

    fetchFingerprint();
  }, []);

  const handleLogoClick = () => {
    if (accessToken) {
      navigate('/profile');
    } else {
      navigate('/');
    }
  };

  const handleLogout = async () => {
    setIsLoading(true); // Показываем лоадер

    try {
      // Отправляем POST-запрос на сервер для выхода пользователя
      try{
        await authApi.post('/api/v1/auth/logout', { fingerprint });
      }catch(err){
        
      }
      // Успешный выход, удаляем токены
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      console.log('Logged out successfully');

      // Перенаправляем на главную страницу
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setIsLoading(false); // Убираем лоадер
    }
  };

  return (
    <nav className="navbar">
      <button onClick={handleLogoClick} className="navbar-button">
        <img src="/logo.png" alt="Site Logo" className="navbar-logo" />
        <span className="navbar-title">{t('StudyGe')}</span>
      </button>
      {accessToken && (
        <button onClick={handleLogout} className="button2">
          {t('Logout')}
        </button>
      )}
      {isLoading && <Loader />} {/* Показываем лоадер, если идет загрузка */}
    </nav>
  );
};

export default Navbar;
