// src/context/ModalContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Modal from '../components/Common/Modal/Modal';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onCloseCallback, setOnCloseCallback] = useState(null);
  const [modalProps, setModalProps] = useState({}); // Для хранения дополнительных пропсов

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Блокировка прокрутки при открытом модальном окне
    } else {
      document.body.style.overflow = ''; // Разблокировка прокрутки при закрытии модального окна
    }

    return () => {
      document.body.style.overflow = ''; // Очистка стиля при размонтировании компонента
    };
  }, [isModalOpen]);

  const showModal = (title, content, options = {}) => {
    setModalTitle(title);
    setModalContent(content);
    setOnCloseCallback(() => options.onClose || null);
    setModalProps(options);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setModalTitle('');
    if (onCloseCallback) {
      onCloseCallback(); // Вызов колбэка, если он задан
    }
    setOnCloseCallback(null);
    setModalProps({}); // Сброс дополнительных пропсов
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {isModalOpen && (
        <Modal
          title={modalTitle}
          content={modalContent}
          onClose={hideModal} // Связываем функцию hideModal с кнопкой закрытия
          {...modalProps} // Передаем дополнительные пропсы в Modal
        />
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
