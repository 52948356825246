// src/pages/HomePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate для перенаправления
import { useTranslation } from 'react-i18next';
import AuthMethods from '../components/AuthMethods/AuthMethods';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Используем useNavigate для навигации

  useEffect(() => {
    // Проверка наличия токенов в localStorage
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // Если токены есть, перенаправляем на страницу профиля
    if (accessToken && refreshToken) {
      navigate('/profile');
    }
  }, [navigate]); // Добавляем navigate в зависимости, чтобы избежать предупреждений React

  return (
    <div className="container">
      <AuthMethods providers={[]} addToken={false}/>
    </div>
  );
};

export default HomePage;
