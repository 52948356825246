import axios from 'axios';
import { getFingerprint } from './fingerprint'; // Импорт функции получения fingerprint

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2000';

const api = axios.create({
  baseURL: API_URL,
});

// Переменная для хранения промиса обновления токенов
let isRefreshing = false;
let refreshTokenPromise = null;

// Интерсептор для добавления токена доступа ко всем запросам
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Функция для получения нового access и refresh токена
const refreshTokens = async (refreshToken) => {

  const fingerprint = await getFingerprint();

  const response = await axios.post(`${API_URL}/api/v1/auth/refresh-token`, {
    refreshToken,
    fingerprint,
  });
  const { access, refresh } = response.data;
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
  return { access, refresh };
};

// Интерсептор для обработки 401 ошибки и обновления токенов
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
          try {
            refreshTokenPromise = refreshTokens(refreshToken);
            const tokens = await refreshTokenPromise;
            isRefreshing = false;
            refreshTokenPromise = null;

            originalRequest.headers['Authorization'] = `Bearer ${tokens.access}`;

            // Повторяем изначальный запрос с новыми токенами
            return api(originalRequest);
          } catch (err) {

            isRefreshing = false;
            refreshTokenPromise = null;
            if(err.response && err.response.status === 401)
            {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
            }
            window.location.href = '/';
          }
        } else {
          window.location.href = '/';
        }
      } else if (refreshTokenPromise) {
        // Если уже идет процесс обновления токенов, ждем его завершения
        const tokens = await refreshTokenPromise;
        originalRequest.headers['Authorization'] = `Bearer ${tokens.access}`;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
