import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const currentYear = new Date().getFullYear();
  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ru', name: 'Русский' },
    { code: 'ar', name: 'العربية' },
    { code: 'ch', name: '中文' },
    { code: 'de', name: 'Deutsch' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'it', name: 'Italiano' },
    { code: 'ja', name: '日本語' },
    { code: 'kr', name: '한국어' },
    { code: 'nl', name: 'Nederlands' },
    { code: 'pl', name: 'Polski' },
    { code: 'pt', name: 'Português' },
    { code: 'ro', name: 'Română' },
    { code: 'tr', name: 'Türkçe' }
  ];

  const links = {
    terms: {
      en: 'https://milevstudio.com/studyge-terms-and-conditions/',
    },
    privacy: {
      en: 'https://milevstudio.com/studyge-privacy-policy/',
    }
  };

  const socialLinks = {
    en: {
      youtube: 'https://www.youtube.com/@studyge_en/shorts',
      tiktok: 'https://www.tiktok.com/@studyge_en',
      instagram: 'https://www.instagram.com/studyge_en/reels/',
    },
    ru: {
      youtube: 'https://www.youtube.com/@studyge_ru/shorts',
      tiktok: 'https://www.tiktok.com/@studyge_ru',
      instagram: 'https://www.instagram.com/studyge_rus/reels/',
      vk: 'https://vk.com/studyge_ru'
    },
    fr: {
      youtube: 'https://www.youtube.com/@studyge_fr/shorts',
    },
    de: {
      youtube: 'https://www.youtube.com/@studyge_de/shorts',
    },
    es: {
      youtube: 'https://www.youtube.com/@studyge_es/shorts',
      tiktok: 'https://www.tiktok.com/@studyge_es',
      instagram: 'https://www.instagram.com/studyge_es/reels/',
    },
    ja: {
      youtube: 'https://www.youtube.com/@studyge_jp/shorts',
      tiktok: 'https://www.tiktok.com/@studyge_jp',
      instagram: 'https://www.instagram.com/studyge_jp/reels/',
    },
    // Добавьте другие языки при необходимости
  };

  // Получаем ссылки для текущего языка или используем английский язык по умолчанию
  const currentSocialLinks = socialLinks[currentLanguage] || socialLinks['en'];
  const currentTermsLink = links.terms[currentLanguage] || links.terms['en'];
  const currentPrivacyLink = links.privacy[currentLanguage] || links.privacy['en'];

  return (
    <footer className="footer">
      <div className='footer-line'>
        <div className="footer-info">

          <select
            className="language-select"
            value={currentLanguage}
            onChange={changeLanguage}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>


          <div className='footer-links'>
            <a href={currentTermsLink} target="_blank">
              {t('footer.terms')}
            </a>

            <a href={currentPrivacyLink} target="_blank">
              {t('footer.privacy')}
            </a>

            <a href="mailto:support@milevstudio.com">{t('footer.contact-us')}</a>
          </div>

          <div className="social-media">
            {currentSocialLinks.youtube && (
            <a href={currentSocialLinks.youtube} target="_blank" rel="noopener noreferrer">
              <img src="/social/youtube.png" alt="Youtube" />
            </a>
            )}
            {currentSocialLinks.tiktok && (
            <a href={currentSocialLinks.tiktok} target="_blank" rel="noopener noreferrer">
              <img src="/social/tiktok.png" alt="Tik Tok" />
            </a>
          )}
            {currentSocialLinks.instagram && (
            <a href={currentSocialLinks.instagram} target="_blank" rel="noopener noreferrer">
              <img src="/social/instagram.png" alt="Instagram" />
            </a>
            )}
            {currentSocialLinks.vk && (
              <a href={currentSocialLinks.vk} target="_blank" rel="noopener noreferrer">
                <img src="/social/vk.png" alt="VK" />
              </a>
            )}
          </div>
        </div>
      </div>

      <div className='footer-cpoyright-line'>
        <div className="footer-info copiright">
          <p>© {currentYear} StudyGe™ — All rights reserved.</p>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
