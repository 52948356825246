// src/components/ProfilePage/UserProfile/UserProfile.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import UserProfileCard from './UserProfileCard';

const UserProfile = ({ userData, ranksData, isPremium }) => {
  const { t } = useTranslation();
  const username = userData.username || 'null';

  return (
    <div className="profile-section">
      <h2 className="profile-section-title">{t('Account Data')}</h2>
      <UserProfileCard
        username={username}
        userId={userData.id}
        points={userData.points}
        frontIconUrl="/icon_front.png" // URL до иконки
        backIconUrl="/icon_back.png"  // URL до иконки
        ranksData={ranksData}  
        isPremium={isPremium}
      />
    </div>
  );
};

export default UserProfile;
