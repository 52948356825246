// src/components/ProfilePage/UserProfile/UserProfileCard.js
import React from 'react';
import './UserProfileCard.css';
import { all } from 'axios';

function getRankId(ranks, points) {
  let rankId = ranks[0].id;
  for (let i = 0; i < ranks.length; i++) {
    if (points >= ranks[i].points) {
      rankId = ranks[i].id;
    } else {
      break;
    }
  }
  return rankId;
}

const UserProfileCard = ({ username, userId, points, frontIconUrl, backIconUrl, ranksData, isPremium = false }) => {
  try {
    let rankIconUrl = `/ranks/rank${getRankId(ranksData, points)}.png`;

    return (
      <div className="user-container profile-section-white-box">
        <div className="icon-container">
          <img src={frontIconUrl} alt="Icon Front" className="icon icon_front" />
          <img src={backIconUrl} alt="Icon Back" className="icon icon_back" />
        </div>
        <div className="text-container">
          <div className="text-title">
            {username}
            {isPremium && (
              <span className="premium-badge">Premium</span>
            )}
          </div>
          <div className="text-description id-text">ID: {userId}</div>
        </div>
        {/* <div className="right-elements">
          <img src={rankIconUrl} alt="Rank Icon" className="right-image" />
          <div className="right-text">{points}</div>
        </div> */}
      </div>
    );
  }
  catch (err) {
    console.error(err);
    alert(err);
    return null;
  }
};

export default UserProfileCard;
