// src/components/Common/ErrorPage/ErrorPage.js
import React from 'react';
import './ErrorPage.css';
import { useTranslation } from 'react-i18next';

const ErrorPage = ({ onRetry }) => {
  const { t } = useTranslation();

  return (
    <div className="error-page">
      <img src="/error-image.png" alt="Error" />
      <h2>{t('Something went wrong')}</h2>
      <button className="button1" onClick={onRetry}>
        {t('Reload page')}
      </button>
    </div>
  );
};

export default ErrorPage;
