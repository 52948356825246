// src/components/SubscriptionOptions/SubscriptionOptions.js

import React, { useState, useEffect } from 'react';
import './SubscriptionOptions.css';
import subscription_icon from '../../../assets/icons/subscription_icon.png';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../context/ModalContext';

const SubscriptionOptions = ({ productsInfo, onPurchased }) => {

  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const [selectedOption, setSelectedOption] = useState(productsInfo.products ? productsInfo.products[0]?.id: null);
  const { i18n } = useTranslation();



  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const getLanguage = () => {

    const languageMap = {
      'ru': 'ru-RU',
      'en': 'en-US',
      'de': 'de-DE',
      'pl': 'pl',
      'pt': 'pt',
      'tr': 'tr-TR',
      'es': 'es-ES',
      'it': 'it'
    };

    // Получаем текущий язык из i18n и соответствующий ему код или код для английского языка по умолчанию
    const currentLanguageCode = languageMap[i18n.language] || 'en-US';

    return currentLanguageCode;
  }

  const handlePayment = () => {
    // Проверяем, что CloudPayments загружен

    let optionId = selectedOption;

    const product = productsInfo.products.find(product => product.id === optionId);

    let chargeObject = {
      publicId: productsInfo.publicId, // Замените на ваш Public ID
      description: t(`Product name ${optionId}`) || "Unknown", // Описание платежа
      amount: getPrice(optionId), // Сумма платежа
      currency: getPriceCurrency(optionId), // Валюта платежа
      accountId: productsInfo.userId,
      requireEmail: true, // Идентификатор пользователя
      skin: 'mini', // Дизайн виджета
    };

    chargeObject.data = {
      type: product.type,
      productId: product.id,
      grantAccess: product.grantAccess,
      grantItems: product.grantItems
    }

    if (product.subscription && product.subscription.interval) {
      chargeObject.data.CloudPayments = {
        recurrent: {
          interval: product.subscription.interval,
          period: product.subscription.period
        },
      }
    }

    if (window.cp) {
      const widget = new window.cp.CloudPayments({ language: getLanguage() });
      widget.charge(chargeObject,
        (options) => { // Success
          onPurchased();
          console.log('Оплата прошла успешно', options);
        },
        (reason, options) => { // Fail
          console.error('Ошибка при оплате', reason, options);
        }
      );
    } else {
      console.error('CloudPayments widget не загружен');
    }
  };


  const getPriceText = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product ? `${product.price} ${product.currency}` : '';
  };

  const getPriceCurrency = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product.currency;
  };

  const getPrice = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product.price;
  };

  if(!productsInfo || !productsInfo.products || productsInfo.products.length === 0) return null;

  
  const isSingleForeverOption = productsInfo.products.length === 1 && productsInfo.products[0].id === 'forever';


  return (
    <div>
      <h2 className="profile-section-title">{t('SubscriptionOptions title')}</h2>
      <div className="profile-section subscription-container profile-section-white-box">
        <p className='subtitle'>{t('SubscriptionOptions available for russia')}</p>
        <p className='info'>{t('Access to premium subscription in the app')}</p>
        <div className="subscription-content">
          {isSingleForeverOption ? (<></>)
            : (
              <>
                <div className="subscription-options">
                  {productsInfo.products.map((product) => (
                    <div
                      key={product.id}
                      className={`subscription-option ${selectedOption === product.id ? 'selected' : ''}`}
                      onClick={() => handleOptionChange(product.id)}
                    >
                      <div className="custom-radio">
                        <input
                          type="radio"
                          id={product.id}
                          name="subscription"
                          value={product.id}
                          checked={selectedOption === product.id}
                          onChange={() => handleOptionChange(product.id)}
                        />
                        <span className="radio-checkmark"></span>
                      </div>
                      <label htmlFor={product.id}>
                        <span className="price">{t(`SubscriptionOptions price ${product.id}`, { price: getPriceText(product.id) })}</span>
                        <span className="description">{t(`SubscriptionOptions ${product.id} subscription`)}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </>
            )}
          <div className="subscription-image">
            <img src={subscription_icon} alt="Subscription Illustration" />
          </div>
        </div>
        <button className="button1 continue-button" onClick={handlePayment}>
          {isSingleForeverOption
            ? (<>{t('SubscriptionOptions purchase for', { price: getPriceText(selectedOption) })}</>)
            : (<>{t('SubscriptionOptions continue button')}</>)}
        </button>

        <p className="footer-text">
          {selectedOption === 'monthly' && t('Cancel anytime.')}
          {selectedOption === 'yearly' && t('Cancel anytime.')}
          {selectedOption === 'forever' && t('One-time payment. Access forever.')}
        </p>
      </div>
    </div>
  );

};

export default SubscriptionOptions;
