// src/components/VerificationCodeInput/VerificationCodeInput.js
import React, { useState, useEffect, useRef } from 'react';
import './VerificationCodeInput.css';
import { useTranslation } from 'react-i18next';

const VerificationCodeInput = ({
  email,
  onVerifyCode,
  isLoading,
  resetCodeFields,
  onResetComplete,
  onGoBack,
  handleResendCode
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [timer, setTimer] = useState(30); // Изначально таймер на 30 секунд
  const [canResend, setCanResend] = useState(false); // Состояние для контроля доступности кнопки повторной отправки
  const intervalRef = useRef(null);
  const inputsRef = useRef([]);

  useEffect(() => {
    if (resetCodeFields) {
      setCode(['', '', '', '', '', '']);
      inputsRef.current[0].focus(); // Фокусируемся на первом поле после очистки
      onResetComplete(); // Уведомляем родительский компонент, что очистка завершена
    }
  }, [resetCodeFields, onResetComplete]);

  useEffect(() => {
    // Запуск таймера при загрузке компонента
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 1) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalRef.current);
          setCanResend(true);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const handleChange = (index, value) => {
    if (isNaN(value)) return; // Игнорируем не числовые символы
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Переход на следующее поле после ввода
    if (value !== '' && index < 5) {
      inputsRef.current[index + 1].focus();
    }

    // Если все поля заполнены, вызываем функцию верификации
    if (newCode.every((num) => num !== '')) {
      onVerifyCode(newCode.join(''));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && code[index] === '' && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleResend = () => {
    if (!canResend) return;

    handleResendCode();
    setCanResend(false);
    setTimer(30);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 1) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalRef.current);
          setCanResend(true);
          return 0;
        }
      });
    }, 1000);
  };

  return (
    <div className="verification-container">
      <h2>{t('VerificationCodeInput.title')}</h2>
      <p>{t('VerificationCodeInput.description')}</p>
      <div className="code-input-container">
        {code.map((num, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={num}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={(el) => (inputsRef.current[index] = el)}
            className="code-input"
            disabled={isLoading}
          />
        ))}
        {isLoading && <div className="loader"></div>}
      </div>

      <div className="resend-container">
        {canResend ? (
          <>
            <span className="resend-text">{t('VerificationCodeInput.resend_text')}</span>
            <button
              className="resend-link"
              onClick={handleResend}
              disabled={!canResend}
            >
              {t('VerificationCodeInput.resend_button')}
            </button>
          </>
        ) : (
          <span className="timer">
            {t('VerificationCodeInput.resend_timer', { seconds: timer })}
          </span>
        )}
      </div>

      <button className="back-button" onClick={onGoBack} disabled={isLoading}>
        {t('VerificationCodeInput.back_button')}
      </button>
    </div>
  );
};

export default VerificationCodeInput;
